import { useContainer } from "@/components/containers/ContainerProvider"
import EntityPage from "@/components/page/EntityPage"
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { DisplayElemSize } from "@/components/table/EntityTable"
import { useTitle } from "@/libs/hooks/useTitle"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import AddDataFormAction from "./actions/StartCampaignAction"
import { Progress, Tooltip } from "@mantine/core"

type Campaign = {
    name: string;
    state: 'ACTIVE';
    pendingRecipients: string[];
    completedRecipients: string[];
}

export default function CampaignList() {
    const { t } = useTranslation()
    const { openModal } = useContainer()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Campaigns (Mockup)"))
    const headers = [
        t('Campaign'),
        t('State'),
        t('Progress')
    ];
    return <EntityPage
        category={t('Collection')}
        title={t('Campaigns (Mockup)')}
    ><ListSection<Campaign>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    key: 'name',
                    label: e.name || '',
                    size: DisplayElemSize.lg,
                }],
            }, {
                display: [
                    {
                        key: 'state',
                        label: e.state === 'ACTIVE' ? 'Active' : '',
                        status: 'green',
                    }
                ]
            }, {
                display: [
                    {
                        key: 'progress',
                        elem: <Progress.Root size={15} style={{maxWidth: 250}}>
                        <Tooltip label={<><div>1 person completed</div><ul style={{margin:0, fontSize: '0.8rem'}}><li>alice.white@sentryfy.com</li></ul></>}>
                          <Progress.Section animated value={Math.floor(e.pendingRecipients.length / (e.completedRecipients.length + e.pendingRecipients.length) * 100) } color="#12b886">
                            <Progress.Label>1 Completed</Progress.Label>
                          </Progress.Section>
                        </Tooltip>
                        <Tooltip label={<><div>1 person didn't completed yet</div><ul style={{margin:0, fontSize: '0.8rem'}}><li>john.doe@sentryfy.com</li></ul></>}>
                          <Progress.Section value={Math.ceil(e.completedRecipients.length / (e.completedRecipients.length + e.pendingRecipients.length) * 100) } color="#f0f0f0">
                            <Progress.Label></Progress.Label>
                          </Progress.Section>
                        </Tooltip>
                      </Progress.Root>
                    }
                ]
            }],
        })}
        load={async () => {
            return {
                list: [{
                    name: 'Financial impact campaign',
                    state: 'ACTIVE',
                    completedRecipients: [ "alice.white@sentryfy.com"],
                    pendingRecipients: ["jon.doe@sentryfy.microsoft.com"]
                }],
                totalPages: 1,
                page: 1,
            }
        }}
        addLabel="Start a Campaign"
        onAdd={() => {
            openModal('start_campaign', <AddDataFormAction></AddDataFormAction>, {
                title: t('Start a Campaign') || ''
            })
        }}
        emptyLabel={t('No campaigns yet')}
    ></ListSection>
    </EntityPage>
}