import { useContainer } from "@/components/containers/ContainerProvider"
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { DisplayElemSize } from "@/components/table/EntityTable"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { GrConfigure } from "react-icons/gr"
import { Badge, Group } from "@mantine/core"
import { InstalledScan, ScanTask, ScansApi } from "@/libs/client"
import { sortBy } from "@/libs/utils/sort"
import { FaPlay, FaTrash } from "react-icons/fa"
import notify from "@/libs/notify/notify"
import { ActionResultType } from "@/components/actions/actions"
import ScanActionForm from "./actions/ScanTaskActionForm"

export default function ScanTaskList() {
    const { t } = useTranslation()
    const { id } = useParams()
    const { projectUrl } = useProject()
    const { openModal, openConfirm } = useContainer()
    const navigate = useNavigate()
    const [installedScan, setInstalledScan] = useState<InstalledScan>()

    const fetchInstalledScan = async () => {
        const resp = await new ScansApi().installedScan(id!)
        setInstalledScan(resp.data)
    }

    useEffect(() => {
        fetchInstalledScan()
    }, [])

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Scan Tasks"))
    const headers = [
        t('common.name'),
    ];
    return installedScan && <ListSection<ScanTask>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    key: 'name',
                    label: e.name || '',
                    size: DisplayElemSize.lg,
                }, {
                    key: 'label',
                    label: e.installedScan?.scanDefinition?.label || '',
                    secondary: true,
                }, {
                    key: 'tags',
                    elem: <Group gap={4}>
                        {Object.entries(e.parameters).sort(sortBy(p => p[0])).map(p => <Badge size="sm" color="black">{p[0]}={p[1].toString()}</Badge>)}
                        {e.labels?.sort(sortBy(l => l)).map(l => <Badge size="sm">{l}</Badge>)}
                    </Group>
                }],
            }],
        })}
        load={async (page) => {
            const resp = await new ScansApi().scanTasks(id!, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        addLabel="Add Task"
        onAdd={() => {
            openModal('add_scan_task', <ScanActionForm installedScanId={installedScan.id!} scanDefinitionId={installedScan.scanDefinition!.id!} onRefresh={() => listRef.current?.refresh()}></ScanActionForm>, {
                title: t('Add Scan Task') || ''
            })
        }}
        onRowClick={b => navigate(projectUrl(`/analysis/scans/installed/${id}/scan_tasks/${b.id}`))}
        rowActions={() => [{
            label: 'Run',
            icon: <FaPlay></FaPlay>,
            execute: async (e) => {
                await new ScansApi().runScanTask(e.id!)
                listRef.current?.refresh()
                notify.info('Started scan ' + e.name)
            }
        },{
            label: 'Update',
            icon: <GrConfigure></GrConfigure>,
            execute: async (e) => {
                openModal('update_scan', <ScanActionForm installedScanId={installedScan.id!} onRefresh={() => listRef.current?.refresh()} scanDefinitionId={installedScan.scanDefinition!.id!} scanTaskId={e.id}></ScanActionForm>, {
                    title: t('Update') || ''
                })
            }
        }, {
            label: 'Delete',
            icon: <FaTrash></FaTrash>,
            execute: (e) => {
                openConfirm('delete', {
                    title: 'Delete',
                    confirmationText: <>Are you sure you want to delete the scan <b>{e.name}</b>?</>,
                    cancelText: "No don't delete",
                    submitText: "Yes, Delete",
                    color: 'red',
                    onSubmit: async () => {
                        await new ScansApi().deleteScanTask(e.id!)
                        listRef.current?.refresh()
                        notify.success(`Scan ${e.name} successfully deleted`)
                        return { type: ActionResultType.SUCCESS }
                    }
                })
            }
        }]}
        /*topActions={[
            {
                label: "Import Tasks",
                icon: <BiDownload></BiDownload>,
                execute: () => {
                    openModal('import_tasks', <ImportScanTasksForm id={id!} onRefresh={listRef.current?.refresh!}></ImportScanTasksForm>, {
                        title: 'Import Tasks'
                    })
                }
            },
            {
                label: "Export Tasks",
                icon: <BiDownload></BiDownload>,
                execute: async () => {
                    const list = await getAllElements(async (page) => (await new ScansApi().scanTasks(id!, page)).data)
                    downloadFileFromObject(list, `scan-tasks-${id}.json`)
                }
            }
        ]}*/
        emptyLabel={t('No scan tasks yet')}
    ></ListSection>
}