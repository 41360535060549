import { useContainer } from "@/components/containers/ContainerProvider"
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { DisplayElemSize } from "@/components/table/EntityTable"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { GrCheckmark, GrConfigure } from "react-icons/gr"
import ConfigureControlForm from "./actions/ConfigureControlForm"
import { Badge, RingProgress, Text } from "@mantine/core"
import ManualChecksForm from "./actions/ManualChecksForm"
import { InstalledScan, ScansApi } from "@/libs/client"
import HIPAA_CONTROLS from "./hipaa"
import NIST_CONTROLS from "./nist"
import { Control, getPercentage } from "./control"

export default function ControlsLanding() {
    const { t } = useTranslation()
    const { id } = useParams()
    const { projectUrl } = useProject()
    const { openModal } = useContainer()
    const navigate = useNavigate()
    const [installedScan, setInstalledScan] = useState<InstalledScan>()

    const fetchInstalledScan = async () => {
        const resp = await new ScansApi().installedScan(id!)
        setInstalledScan(resp.data)
    }

    useEffect(() => {
        fetchInstalledScan()
    }, [])
    const isHipaa = useMemo(() => installedScan?.scanDefinition?.label?.toLowerCase().includes("hipaa"), [installedScan])
    const list = useMemo(() => isHipaa ? HIPAA_CONTROLS : NIST_CONTROLS, [isHipaa])

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Controls"))
    const headers = [
        t('common.name'),
        t('common.state'),
        t('Criteria'),
    ];
    return installedScan && <ListSection<Control>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    key: 'label',
                    label: e.label || '',
                    size: DisplayElemSize.lg,
                }, {
                    key: 'tags',
                    elem: e.tags?.map(t => <Badge size="xs">{t}</Badge>)
                }],
            }, {
                display: [{
                    key: 'state',
                    label: 'Not Respected',
                    status: 'red'
                }]
            }, {
                display: [{
                    key: 'progress',
                    elem: <RingProgress
                    size={60}
                    thickness={4}
                    label={
                        <Text size="xs" ta="center">
                        {getPercentage(e)} %
                        </Text>
                    }
                    sections={[
                        { value: getPercentage(e), color: 'teal' },
                    ]}
                />
                }]
            }],
        })}
        load={async () => {
            return { list };
        }}
        onRowClick={(e) => navigate(projectUrl(`/analysis/scans/installed/${installedScan.id}/controls/${e.id}`))}
        rowActions={() => [{
            label: 'Configure',
            icon: <GrConfigure></GrConfigure>,
            execute: async (e) => {
                openModal('configure_control', <ConfigureControlForm />, {
                    title: t(`Configure ${e.label}`) || ''
                })
            }
        }, {
            label: 'Manual Checks',
            icon: <GrCheckmark></GrCheckmark>,
            execute: async () => {
                openModal('manual_checks', <ManualChecksForm />, {
                    title: t('Manual Checks') || ''
                })
            }
        }]}
        headerLeftSection={<RingProgress
            size={60}
            thickness={4}
            label={
                <Text size="xs" ta="center">
                {list.filter(e => e.enabled).reduce((acc, _) => {
                    return acc
                }, 0)} %
                </Text>
            }
            sections={[
                { value: list.filter(e => e.enabled).reduce((acc, _) => {
                    return acc
                }, 0), color: 'teal' },
            ]}
        />}
        emptyLabel={t('No controls yet')}
    ></ListSection>
}