import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Catalog, CatalogsApi, ScanDefinition } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function OpsCatalogConditions() {
    const { id } = useParams()
    const { t } = useTranslation()
    const [catalog, setCatalog] = useState<Catalog>()
    
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("scans.condition_other"))
    const headers = [
        t('common.name'),
        t('common.latest_version'),
    ];

    const fetchCatalog = async () => {
        const resp = await (new CatalogsApi().catalog(id!))
        setCatalog(resp.data)
    }
    useEffect(() => {
        fetchCatalog()
    }, [])

    return catalog && <ListSection<ScanDefinition>
            callbacks={listRef}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                    display: [{
                        key: 'label',
                        label: e.label || '',
                    }, {
                        key: 'name',
                        label: e.name || '',
                        secondary: true
                    }],
                },{
                    display: [{
                        key: 'version',
                        label: '0.1',
                        size: DisplayElemSize.lg,
                    }],
                } ],
            })}
            load={async (page: number) => {  
                const resp =  await new CatalogsApi().catalogScanConditions(id!, page)
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            emptyLabel={t('scans.no_conditions_yet')}
        ></ListSection>
}