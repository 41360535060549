import { useContainer } from "@/components/containers/ContainerProvider";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { DeploymentEvent, DeploymentsApi } from "@/libs/client";
import { Badge, Group } from "@mantine/core";
import CodeMirror from "react-codemirror"
import moment from "moment";
import { useRef } from "react";
import { FaParagraph } from "react-icons/fa";
import { useParams } from "react-router-dom";

function getMessage(e: DeploymentEvent): string {
    if (e.type === 'STATUS') {
        return e.status === 'UP' ? 'Deployment is up' : 'Deployment is down'
    }
    return (e.message || '').substring(0, Math.min(150, e.message?.length || 0))
}

function getCountBadgeColor(e: DeploymentEvent): string {
    if (e.type === 'STATUS') {
        return e.status === 'UP' ? 'green' : 'red'
    }
    return 'red'

}

export default function DeploymentEvents() {
    const { id } = useParams()
    const { openModal } = useContainer()
    const listRef = useRef<ListSectionCallbacks>()

    const headers = [
        'Message',
        'Date'
    ];
    return <ListSection<DeploymentEvent>
            headers={headers}
            callbacks={listRef}
            renderRow={(e) => ({
                cols: [
                    {
                        display: [{
                            key: 'message',
                            elem: <Group gap={3}>{e.count > 1 ? <Badge color={getCountBadgeColor(e)}>{e.count}</Badge>: undefined}<div>{getMessage(e)}</div></Group>
                        }, {
                            key: 'type',
                            label: e.type,
                            size: DisplayElemSize.xs
                        }]
                    },
                    {
                        display: [{
                            key: 'date',
                            elem: moment(e.updatedDate || e.createdDate).fromNow()
                        }]
                    }
                ],
            })}
            load={async (page) => {
                const res = await new DeploymentsApi().deploymentEvents(id!, page)
                return res.data
            }}
            rowActions={(e) => [
                {
                    label: 'View Details',
                    icon: <FaParagraph></FaParagraph>,
                    execute: () => {
                        openModal('view_details', <CodeMirror value={e.details} options={{lineNumbers: true, theme: 'neo'}}>
                        </CodeMirror>, {
                            title: 'View Details'
                        })
                    }
                }   
            ]}
            emptyLabel={'No events yet'}
        ></ListSection>
}