import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { OpsTenantsApi, Tenant } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useContainer } from "@/components/containers/ContainerProvider";
import { useRef } from "react";
import { AddTenant } from "./actions/AddTenant";

export default function TenantsLanding() {
    const { t } = useTranslation();
    const { openModal } = useContainer();
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Tenants"))
    const headers = [
        t('common.name'),
        'Number of Projects'
    ];
    return <EntityPage
        category={t('common.manage')}
        title={t("Tenants")}>
        <ListSection<Tenant>
            callbacks={listRef}
            load={async (page) => {
                const resp = await new OpsTenantsApi().tenants(page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            headers={headers}
            renderRow={(e) => ({
                cols: [
                    {
                        display: [{
                            key: 'display_name',
                            label: e.displayName || e.name || '',
                            size: DisplayElemSize.lg,
                        }], 
                    },
                    {
                        display: [{
                            key: 'num_projects',
                            label: e.numberOfProjects + '',
                        }], 
                    }
                ],
            })}
            addLabel="Add Tenant"
            onAdd={() => openModal('add_tenant', <AddTenant onRefresh={() => listRef.current?.refresh()}></AddTenant>, {
                title: t('Add Tenant') || ''
            })}
            emptyLabel={t("No tenants were found")}
        ></ListSection>
    </EntityPage>
}