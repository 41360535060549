import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { DeploymentsApi, DeploymentSecretKey } from "@/libs/client";
import { ActionIcon, CopyButton, Group, rem, Tooltip } from "@mantine/core";
import moment from "moment";
import { useRef, useState } from "react";
import { FaCheck, FaCopy, FaKey, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

export default function DeploymentSecretKeys() {
    const { id } = useParams()
    const { openConfirm } = useContainer()
    const [secretsMap, setSecretsMap] = useState({} as {[key: string]: string})
    const listRef = useRef<ListSectionCallbacks>()
    const fetch = async () => {
        const res = await new DeploymentsApi().deployment(id!)
        if (res.status == 200) {
            return res.data
        } else {
            //TODO
        }
    }
    const headers = [
        'Display',
        'Expiration Date'
    ];
    return <ListSection<DeploymentSecretKey>
            headers={headers}
            callbacks={listRef}
            renderRow={(e) => ({
                cols: [
                    {
                        display: [{
                            key: 'secret',
                            elem: secretsMap[e.id!] ? <Group gap={2}>
                            <div>{secretsMap[e.id!]}</div>
                            <CopyButton value={secretsMap[e.id!] + ''} timeout={2000}>
                            {({ copied, copy }) => (
                                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : '#c0c0c0'} variant="subtle" onClick={copy}>
                                    {copied ? (
                                    <FaCheck style={{ width: rem(12) }} />
                                    ) : (
                                    <FaCopy style={{ width: rem(12) }} />
                                    )}
                                </ActionIcon>
                                </Tooltip>
                            )}
                            </CopyButton>
                        </Group> : <div>{e.display || ''}</div>,
                            size: DisplayElemSize.lg,
                        }],
                    },
                    {
                        display: [{
                            key: 'expiration',
                            label:e.expirationDate ? moment(e.expirationDate).format('YYYY-MM-DD HH:mm:ss') : 'Never'
                        }]
                    }
                ],
            })}
            load={async () => {
                const data = await fetch()
                return { list: data?.secretKeys || [], totalPages: 1, page: 1 }
            }}
            addIcon={<FaKey></FaKey>}
            addLabel="Generate Secret Key"
            onAdd={async () => {
                const resp = await new DeploymentsApi().createDeploymentSecret(id!)
                listRef.current?.refresh();
                if (resp.status < 300) {
                    setSecretsMap(s => ({ ...s, [resp.data.id!]: resp.data.secretKey!}))
                    return { type: ActionResultType.SUCCESS }
                }
                return { type: ActionResultType.FAILURE }
            }}
            emptyLabel={'No secret keys yet'}
            rowActions={r => [
                {
                    label: 'Delete',
                    icon: <FaTrash></FaTrash>,
                    execute: async () => {
                        openConfirm('delete_deployment', {
                            title: 'Delete Deployment',
                            confirmationText: <>Are you sure you want to delete the secret key <b>{r.display}</b>?</>,
                            cancelText: "No don't delete",
                            submitText: "Yes, Delete",
                            color: 'red',
                            onSubmit: async () => {
                                const resp = await new DeploymentsApi().deleteDeploymentSecret(id!, r.id!)
                                if (resp.status === 204) {
                                    listRef.current?.refresh();
                                    return { type: ActionResultType.SUCCESS }
                                }
                                return { type: ActionResultType.FAILURE }
                            }
                        })
                    }
                }
            ]}
        ></ListSection>
}