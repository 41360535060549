import { Badge, Pagination, Table } from "@mantine/core";
import EmptyList from "../empty/EmptyList";
import { ReactNode, useEffect, useState } from "react";
import { EntityAction } from "../actions/actions";
//import { isOperator } from "@/libs/common/operator";
import classes from './EntityTable.module.css';
import cx from 'clsx';
import { useAuth } from "@/libs/auth/AuthProvider";
import ActionDropdown from "../actions/ActionDropdown";

export enum DisplayElemSize {
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg"
}

export type DisplayElem = {
    key: string
    label?: string
    elem?: ReactNode
    size?: DisplayElemSize
    secondary?: boolean
    status?: string
}

const displayCss = (e: DisplayElem) => ({
    ...(e.size === DisplayElemSize.xs ? {
        fontSize: '0.75rem',
    } : null),
    ...(e.size === DisplayElemSize.sm ? {
        fontSize: '0.8rem',
    } : null),
    ...(!e.size || e.size === DisplayElemSize.md ? {
        fontSize: '0.9rem',
    } : null),
    ...(e.size === DisplayElemSize.lg ? {
        fontSize: '1rem',
    } : null),
    ...(e.secondary ? {
        color: '#909090',
    } : null),
})

export type Col = {
    display: DisplayElem | DisplayElem[]
}

export type Row = {
    cols: Col[]
}

export type HeaderCol = {
    label: string
    width?: number
}

type LogoProps<T> = {
    elem?: (entity: T) => ReactNode;
    getUrl?: (entity: T) => string;
    headerIcon: ReactNode;
    width?: number;
    maxWidth?: number;
    height?: number;
    maxHeight?: number;
}

export type EntityTableProps<T> = {
    style?: React.CSSProperties
    headers: (string | HeaderCol)[]
    list?: T[]
    renderRow: (entity: T) => Row
    totalPages?: number
    page?: number
    onPageChange?: (page: number) => void
    emptyLabel: string
    onRowClick?: (row: T) => void
    rowActions?: (entity: T) => EntityAction<T>[]
    logo?: LogoProps<T>
};

type EntityRowProps<T> = {
    entity: T
    renderRow: (entity: T) => Row
    onClick?: (row: T) => void
    actions?: EntityAction<T>[]
    hasActions?: boolean
    logo?: LogoProps<T>
}

function ActionsCol<T>({ entity, actions } : {entity: T, actions: EntityAction<T>[] }) {
    return <td align="right" style={{width: 80}}>
        <ActionDropdown entity={entity} actions={actions}></ActionDropdown>
    </td>
}

function EntityRow<T>({ entity, renderRow, onClick, actions, hasActions, logo }: EntityRowProps<T>) {
    const getDisplays = (de: DisplayElem | DisplayElem[]) => {
        if(Array.isArray(de)) {
            return de as DisplayElem[];
        } else {
            return [de as DisplayElem];
        }
    };
    const row = renderRow(entity)
    const renderDisplay = (de: DisplayElem) => <div key={de.key} style={displayCss(de)}>
        { de.status ? <Badge variant="filled" color={de.status} size="md">{de.label || de.elem}</Badge> : de.label || de.elem }
    </div>
    return <tr>
        { logo ? <td className={classes.logo}>
                { logo.getUrl ? <img src={logo.getUrl(entity)} width={logo.width} height={logo.height} style={{maxWidth: logo.maxWidth, maxHeight: logo.maxHeight}}></img> : <></> }
                { logo.elem ? logo.elem(entity) : <></>}
            </td> : <></> }
        {row.cols.map(c => <td key={Array.isArray(c.display) ? c.display.map(d => d.key).toString() : c.display.key} onClick={() => onClick ? onClick(entity) : undefined}>
            { 
                getDisplays(c.display).map(renderDisplay)
            }
        </td>)}
        {hasActions && <ActionsCol<T> entity={entity} actions={actions!}></ActionsCol>}
    </tr>
}

export default function EntityTable<T>({ style, list, renderRow, headers, emptyLabel, rowActions, onRowClick, totalPages, page, logo, onPageChange }: EntityTableProps<T>) {
    const { isOperator } = useAuth()
    const [allActions, setAllActions] = useState<EntityAction<T>[][]>([])
    useEffect(() => {
        setAllActions((list || []).map(e => rowActions ? rowActions(e) : []))
    }, [list])
    return <div className={classes.container} style={style}>
        <Table variant='simple' 
            className={cx(classes.table, isOperator() ? classes.tableOps : null)}
        >
            { list?.length ? <thead>
                <tr>
                    { logo ? <th style={{fontSize: '1.2rem', width: logo.width, height: logo.height, maxHeight: logo.maxHeight, maxWidth: logo.maxWidth}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {logo.headerIcon}
                        </div>
                    </th> : <></>}
                    {headers.map(h => {
                        if (typeof h === 'string') {
                            return <th key={h}>{h}</th>
                        }
                        return <th key={h.label} style={{width: h.width}}>{h.label}</th>
                    })}
                    {!!allActions.find(a => a.length > 0) && <th></th>}
                </tr>
            </thead> : undefined }
            <tbody>
                { (list || []).map((l, idx) => <EntityRow<T> key={(l as any).id} entity={l} renderRow={renderRow} onClick={onRowClick} actions={allActions[idx]} hasActions={!!allActions.find(a => a.length > 0)} logo={logo}></EntityRow>) }
            </tbody>
            </Table>
            {
                !!(list || []).length && 
                    (<div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end',  paddingRight: 30 }}>
                        <Pagination value={page} onChange={onPageChange} total={totalPages || 0} radius="md" />
                    </div>)
            }
            {
                list && !(list || []).length &&
                    (<EmptyList emptyLabel={emptyLabel}></EmptyList>)
            }
        </div>
}