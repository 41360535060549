import { useTranslation } from "react-i18next";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { Ticket, TicketStateEnum, TicketsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { useProject } from "@/libs/project/ProjectProvider";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaSync, FaThumbsDown, FaTimesCircle, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useContainer } from "@/components/containers/ContainerProvider";
import { ActionResultType } from "@/components/actions/actions";
import notify from "@/libs/notify/notify";
import { Badge } from "@mantine/core";

const stateColor = (state: TicketStateEnum) => {
    switch (state) {
        case "APPROVED":
            return "green"
        case "AWAITING_APPROVAL":
            return "yellow"
        case "DECLINED":
            return "red"
    }
}

export default function TicketsLanding() {
    const { t } = useTranslation()
    const { curProject, projectUrl } = useProject()
    const navigate = useNavigate()
    const { openConfirm } = useContainer()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("tickets.label"))
    const headers = [
        t('common.title'),
        t('common.state'),
        t('common.created'),
    ];
    return <EntityPage
        category={t('tracking.label')}
        title={t('tickets.label')}>
        <ListSection<Ticket>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    key: 'title',
                    elem: <><span style={{fontSize: '0.8rem', color: '#c0c0c0'}}>{`#${e.ticketNumber}: `}</span> {e.title}</>,
                    size: DisplayElemSize.lg
                }, {
                    key: 'synched',
                    elem: e.state === 'APPROVED' ? <Badge size="sm" variant="light" color={e.synched ? 'green' : 'red'}
                        leftSection={e.synched ? <FaCheckCircle></FaCheckCircle> : <FaTimesCircle></FaTimesCircle>}
                    >
                        {e.synched ? e.synchedTicketNumber : t('tickets.not_synched_yet')}
                    </Badge> : <></>
                }],
            }, {
                display: [{
                    key: 'state',
                    label: e.state || '',
                    size: DisplayElemSize.sm,
                    status: stateColor(e.state!),
                }],
            }, {
                display: [{
                    key: 'created',
                    label: moment(e.createdDate).fromNow(),
                }]
            }],
        })}
        load={async (page) => {
            const resp = await new TicketsApi().tickets(curProject.id!, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        rowActions={e => {
            const approve = {
                label: t('common.approve'),
                icon: <FaSync></FaSync>,
                execute: (e: Ticket) => {
                    openConfirm('approve_ticket', {
                        title: 'Approve ticket',
                        confirmationText: <>Are you sure you want to approve the ticket #{e.ticketNumber} <b>{e.title}</b>?</>,
                        cancelText: "No don't approve",
                        submitText: "Yes, Approve",
                        color: 'green',
                        onSubmit: async () => {
                            await new TicketsApi().approveTicket(e.id!)
                            //TODO
                            notify.success(`Ticket #${e.ticketNumber} successfully approved`)
                            listRef.current?.refresh()
                            return { type: ActionResultType.SUCCESS }
                        }
                    })
                }
            }
            const deleteAction = {
                label: t('common.delete'),
                icon: <FaTrash></FaTrash>,
                execute: () => {
                    openConfirm('delete', {
                        title: 'Delete ticket',
                        confirmationText: <>Are you sure you want to delete the ticket #{e.ticketNumber} <b>{e.title}</b>?</>,
                        cancelText: t('common.no_delete'),
                        submitText: t('common.yes_delete'),
                        color: 'red',
                        confirmInput: {
                            data: e.ticketNumber + '',
                            placeholder: t('tickets.please_enter_ticket_number_delete')
                        },
                        onSubmit: async () => {
                            await new TicketsApi().deleteTicket(e.id!)
                            listRef.current?.refresh()
                            notify.success(`Ticket #${e.ticketNumber} successfully deleted`)
                            return { type: ActionResultType.SUCCESS }
                        }
                    })
                }
            }
            if (e.state === 'AWAITING_APPROVAL') {
                return [
                    approve,
                    {
                        label: t('common.decline'),
                        icon: <FaThumbsDown></FaThumbsDown>,
                        execute: () => {
                            openConfirm('decline_ticket', {
                                title: 'Decline ticket',
                                confirmationText: <>Are you sure you want to decline the ticket #{e.ticketNumber} <b>{e.title}</b>?</>,
                                cancelText: "No don't decline",
                                submitText: "Yes, Decline",
                                color: 'red',
                                onSubmit: async () => {
                                    await new TicketsApi().declineTicket(e.id!)
                                    listRef.current?.refresh()
                                    notify.success(`Ticket #${e.ticketNumber} successfully declined`)
                                    return { type: ActionResultType.SUCCESS }
                                }
                            })
                        }
                    },
                    deleteAction
                ]
            } else if (e.state === 'DECLINED') {
                return [approve, deleteAction]
            }
            return [deleteAction]
        }}
        topActions={[{
            label: t('common.force_sync'),
            icon: <FaSync></FaSync>,
            execute: async () => {
                (await new TicketsApi().forceSyncTickets(curProject.id!));
                notify.success(`Tickets successfully added to synching queue`)
            }
        }]}
        onRowClick={r => navigate(projectUrl(`/insights/tickets/${r.id}`))}
        emptyLabel={t('tickets.no_tickets_yet')}
    ></ListSection>
    </EntityPage>
}