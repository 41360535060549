import { Control, ControlParameter } from "./control";
import { RAW_NIST } from './rawnist';

function replaceLabels(label: string, params: any[] = []) {
    let newLabel = label
    for (let p of params) {
        //if (p["select"]) {
            //newLabel = newLabel.replaceAll(`{{ insert: param, ${p["id"]} }}`, `{{ choose one or more: ${p["select"]["choice"]} }}`)
        //} else {
            //newLabel = newLabel.replaceAll(`{{ insert: param, ${p["id"]} }}`, `{{ ${p["label"]} }}`)
        //}
        newLabel = newLabel.replaceAll(`{{ insert: param, ${p["id"]} }}`, `{{ ${p["id"]} }}`)
    }
    return newLabel
}

const paramsWithoutLabels = RAW_NIST.flatMap(g => g["controls"]).map(c => {
    return c["params"]?.map((p: any) => {
        if (p["select"]) {
            return p
        }
    }) || []
}).flatMap(p => p).filter(p => p)

const selectLabels = paramsWithoutLabels.reduce((acc, p: any) => {
    if (p["select"]["choice"].includes("organization-level")) {
        acc[p["id"]] = { label: "risk management level", description: "The organization's overall approach to managing risk" }
    } else if(p["select"]["choice"].includes("network") && p["select"]["choice"].includes("local")) {
        acc[p["id"]] = { label: "connection type", description: "Type of connection used for local network communication" }
    } else if(p["select"]["choice"].includes("record") && p["select"]["choice"].includes("view")) {
        acc[p["id"]] = { label: "monitoring type", description: "Methods used for monitoring and reviewing recorded data"  }
    } else if (p["id"] === "sc-31_odp") {
        acc[p["id"]] = { label: "channel type", description: "Type of communication channel being utilized" }
    } else if (p["select"]["choice"].includes("physical locations")) {
        acc[p["id"]] = { label: "location type", description: 'Types of location that require security considerations' }
    } else if (p["id"] === "ac-07_odp.03") {
        acc[p["id"]] = { label: "account lockout action", description: 'Actions taken when an account lockout policy is triggered' }
    }
    return acc
}, {} as { [key: string]: { label: string, description: string | undefined } })
console.log(selectLabels)
console.log(paramsWithoutLabels.filter((p: any) => !selectLabels[p["id"]]))

function buildParameterDescriptors(params: any[]): ControlParameter[] | undefined {
    const buildLabel = (p: any) => p["label"] || selectLabels[p["id"]]?.label
    const buildDescription = (p: any) => p["description"] || selectLabels[p["id"]]?.description
    const labelTotalCount = params?.map(buildLabel).reduce((acc, cur) => { 
        acc[cur] = acc[cur] || 0
        acc[cur]++
        return acc
    }, {} as {[key: string]: number}) || {}
    const labelCurrentCount = {} as {[key: string]: number}
    return params?.filter(p => p["id"].includes("_odp."))?.map(p => {
        const labelWithoutCount = buildLabel(p)
        let label = labelWithoutCount
        if (labelTotalCount[labelWithoutCount] > 1) {
            labelCurrentCount[labelWithoutCount] = labelCurrentCount[labelWithoutCount] || 0
            labelCurrentCount[labelWithoutCount]++
            label += ` (${labelCurrentCount[labelWithoutCount]})`
        }
        const description = buildDescription(p)
        let options
        if (p["select"]) {
            options =  p["select"]["choice"].map((ch: string) => ({value: ch, label: ch}))
            if (options.filter((o: any) => o.label.includes("{{"))) {
                console.log(p["id"])
            }
        }
        return {
            id: p["id"],
            label,
            description,
            options
        }
    });
}

function mapRawToControl(c: any, enabled: boolean): Control {
    return {
        id: c["id"],
        label: c["id"].toUpperCase() + ": " + c["label"],
        type: "nist",
        tags: [],
        enabled,
        parameters: {},
        sections: [{ id: c["id"], label: c["id"].toUpperCase() + ": " + c["label"], 
            parameterDescriptors: buildParameterDescriptors(c["params"]),
            criteria: c["flatten_objectives"].map((fo: any) => ({
                id: fo["id"],
                label: replaceLabels(fo["label"], c["params"]),
                checked: ["ac-1_obj.a-1", "ac-1_obj.a-3"].includes(fo["id"]) || fo["id"].startsWith("ia-2"),
                mode: 'MANUAL',
                enabled: true,
                data: {},
            }))}, ...(c["enhancements"] || []).map((e: any) => ({
                id: e["id"],
                label: `${e["id"].toUpperCase()} (Enhancement): ${e["label"]}`,
                parameterDescriptors: buildParameterDescriptors(e["params"]),
                criteria: e["flatten_objectives"].map((fo: any) => ({
                    id: fo["id"],
                    label: replaceLabels(fo["label"], e["params"]),
                    checked: e["id"].startsWith("ia-2"),
                    mode: 'ia-2.1' == e["id"] ? 'AUTOMATIC' : 'MANUAL',
                    enabled: 'ia-2.1' == e["id"],
                    data: {},
                }))
            }))]
    }
}

const NIST_CONTROLS: Control[] = RAW_NIST.flatMap((g: any) => 
    g["controls"].flatMap((c: any) => [mapRawToControl(c, true)])
)

/*const OLD_NIST_CONTROLS: Control[] = [{
    id: 'ac-2',
    label: 'AC-2: Account Management',
    type: 'nist',
    tags: ['P1'],
    sections: [
        { 
            id: 'account_creation',
            label: "1. Account Creation", criteria: [
                { id: "authorization", checked: true, label: "Account Authorization", description: 'Ensure accounts are authorized by a designated authority', mode: 'AUTOMATIC', configElement: <div>TODO</div> },
                { id: "documentedProcedures", checked: true, label: "Documented Procedures", description: 'Check if account creation procedures are documented', mode: 'MANUAL', configElement: <div><TextInput label="Documentation URL"></TextInput></div> },
                { id: "leastPrivilege", label: "Least Privilege Principle", description: 'Verify that accounts are assigned the minimum necessary permissions', mode: 'AUTOMATIC', configElement: <div>TODO</div>  },
            ]
        }
    ]
}, {
    id: 'ia-2',
    label: 'IA-2: Identification And Authentication (Organizational Users)',
    type: 'nist',
    tags: ['P1'],
    sections: [{
        id: '',
        label: '', 
        criteria: [
            { id: 'authenticates', checked: true, label: 'The information system uniquely identifies and authenticates organizational users (or processes acting on behalf of organizational users).', mode: 'AUTOMATIC', configElement: <div>TODO</div>  },
        ]
    },
    {
        id: 'network_access_to_priviledge_accounts',
        label: 'IA-2(1): Network Access To Privileged Accounts',
        criteria: [{
             id: 'mfa_network', checked: false, label: 'The information system implements multifactor authentication for network access to privileged accounts.', mode: 'AUTOMATIC', configElement: <div>
                 <TagsInput label="Priviledged User Groups"></TagsInput>
             </div> 
        }]
    }],
}]*/
export default NIST_CONTROLS;