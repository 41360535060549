import { Checkbox, Tooltip, ActionIcon, Group, Table, Badge, Flex, Menu, Button, RingProgress, Text, Anchor } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FaCaretDown, FaCheckSquare, FaChevronDown, FaChevronRight, FaCog, FaInfoCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import controlStore from '../control.store';
import { Control, ControlCriteriaSection, ControlParameter, Criteria, getPercentage } from '../control';
import { useEffect, useMemo, useState } from 'react';
import { useProject } from '@/libs/project/ProjectProvider';
import { ScansApi } from '@/libs/client';

interface CriteriaLabelProps {
    label: string;
    data: { [key: string]: any };
    parameterDescriptors: ControlParameter[];
    href?: string
}
  
const CriteriaLabel = ({ label, data, parameterDescriptors, href }: CriteriaLabelProps) => {
    const interpolation = useMemo(() => parameterDescriptors.reduce((acc, cur) => {
        acc[cur.id] = cur.label
        return acc
    }, {} as {[key: string]: string}), [parameterDescriptors])
    const labelSplit = label.split(/(\{\{.*?\}\}|\s+)/).filter(Boolean);
    const regex = /{{\s*([^}]+)\s*}}/g;
    const labelElem = labelSplit.map(l => {
        const result = regex.exec(l)
        if (result) {
            const trimmedKey = result[1].trim();
            if (data[trimmedKey]) {
                return <span style={{ fontWeight: 600 }}>{data[trimmedKey]}</span>;
            }
            if (interpolation && interpolation[trimmedKey]) {
                return <span style={{ fontWeight: 600, color: '#d9534f' }}>{`{{ ${interpolation[trimmedKey]} }}`}</span>;
            }
            return <span style={{ fontWeight: 600, color: '#d9534f' }}>{l}</span>;  
        } 
        return l
    })
    if (href) return <Anchor size="sm" component={Link} to={href}>{labelElem}</Anchor>
    return labelElem
  };

function ControlCriteriaRow({ control, criteria, parameterDescriptors } : { control: Control, parameterDescriptors: ControlParameter[], criteria: Criteria }) {
    const [opened, handlers] = useDisclosure(false);
    return <><Table.Tr>
            <Table.Td><Checkbox style={{visibility: criteria.enabled ? 'visible' : 'hidden' }} color="teal" value={criteria.id} size='xs' checked={criteria.checked} /></Table.Td>
            <Table.Td onClick={handlers.toggle} style={{visibility: !!criteria.configElement && criteria.enabled ? 'visible' : 'hidden'}}>
                { opened ? <FaChevronDown color='#5f5f5f' size="0.7rem"></FaChevronDown> : <FaChevronRight color='#5f5f5f' size="0.7rem"></FaChevronRight> }
            </Table.Td>
            <Table.Td onClick={handlers.toggle} style={{maxWidth: '25vw', paddingTop: 10, paddingBottom: 10}}>
                <Group gap={4}>
                    <div style={{ textDecoration: criteria.enabled ? undefined : 'line-through'}}>
                        <CriteriaLabel label={criteria.label} data={control.parameters} href={criteria.href} parameterDescriptors={parameterDescriptors}></CriteriaLabel>
                    </div>
                    {criteria.description ? <Tooltip label={criteria.description} position="right" withArrow>
                        <ActionIcon variant="transparent"><FaInfoCircle size={12} /></ActionIcon>
                    </Tooltip> : <></>}
                </Group>
            </Table.Td>
            <Table.Td>
                <Badge variant='light' color={criteria.enabled ? undefined : 'red' }>{criteria.enabled ? criteria.mode : 'Disabled'}</Badge>
            </Table.Td>
            <Table.Td>
            {criteria.progress !== undefined ? <RingProgress
                    size={40}
                    thickness={2}
                    label={
                        <Text size="0.6rem" ta="center">
                        {criteria.progress} %
                        </Text>
                    }
                    sections={[
                        { value: criteria.progress, color: 'teal' },
                    ]}
                /> : undefined}
            </Table.Td>
        </Table.Tr>
        { opened && criteria.configElement ? <Table.Tr>
            <Table.Td colSpan={2}></Table.Td>
            <Table.Td colSpan={2}>
                {criteria.configElement}
            </Table.Td>
        </Table.Tr> : <></>}
        </>
}


function ControlTableSection({ control, criteriaSection } : { control: Control, criteriaSection: ControlCriteriaSection}) {
    const allDisabled = useMemo(() => {
        return !criteriaSection.criteria.find(c => c.enabled)
    }, [criteriaSection])
    const [opened, handlers] = useDisclosure(!allDisabled);
    return <>
        {criteriaSection.label ? <Table.Tr style={{backgroundColor: '#f8f8f8', fontWeight: 500, borderTop: '1px solid #e8e8e8', borderBottom: '1px solid #e8e8e8', padding: '0.4rem 0.8rem', color: '#808080', textDecoration: allDisabled ? 'line-through' : undefined}}>
            <Table.Td onClick={handlers.toggle}>
                { opened ? <FaChevronDown color='#5f5f5f' size="0.7rem"></FaChevronDown> : <FaChevronRight color='#5f5f5f' size="0.7rem"></FaChevronRight> }
            </Table.Td>
            <Table.Td colSpan={2} onClick={handlers.toggle}>{criteriaSection.label}</Table.Td>
            <Table.Td>
                { allDisabled && <Badge variant='light' color='red'>Disabled</Badge>}
            </Table.Td>
            <Table.Td></Table.Td>
        </Table.Tr> : <></>}
        {opened && criteriaSection.criteria.map(c => <ControlCriteriaRow control={control} parameterDescriptors={[...(control.parameterDescriptors || []), ...(criteriaSection.parameterDescriptors || [])]} criteria={c} />)}
    </>
}

function ActionDropdown() {
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button size='xs' rightSection={<FaCaretDown></FaCaretDown>}>Actions</Button>
        </Menu.Target>
  
        <Menu.Dropdown>
          <Menu.Item leftSection={<FaCog size={14} />}>Configure</Menu.Item>
          <Menu.Item leftSection={<FaCheckSquare size={14} />}>Fill Manual Checks</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }

export default function ControlDetails() {
    const { controlId, id } = useParams()
    const control = controlStore.find(controlId!)!
    const [loading, setLoading] = useState(true)
    const { projectUrl, curProject } = useProject()
    const fetchNistUrl = async () => {
        const resp = await new ScansApi().installedScans(curProject.id!!)
        const nistId = resp.data.list?.find(l => l.scanDefinition?.label?.includes("NIST"))?.id
        const curScan = resp.data.list?.find(l => l.id === id)
        if (nistId && curScan?.scanDefinition?.label?.includes("HIPAA")) {
            control.sections?.forEach(s => s.criteria?.forEach(c => {
                c.href = projectUrl(`/analysis/scans/installed/${nistId}/controls/${c.id}`)
            }))
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchNistUrl()
    }, [])
    return !loading && <div>
        {false && <div>
            <div>Revision: 5</div>
            <Group>
                <div>Threats Addressed:</div>
                <Badge color="red">Spoofing</Badge>
                <Badge color="green">Repudiation</Badge>
            </Group>
            <Group>
            <div>Baseline:</div>
            <Badge color="red" rightSection={<FaInfoCircle></FaInfoCircle>}>Low</Badge>
            </Group>
        </div>}
        <Flex justify={'flex-end'}>
            <Group style={{fontSize: '0.8rem'}} gap={20}>
                <RingProgress
                    size={50}
                    thickness={3}
                    label={
                        <Text size="xs" ta="center">
                        {getPercentage(control)} %
                        </Text>
                    }
                    sections={[
                        { value: getPercentage(control), color: 'teal' },
                    ]}
                />
                <ActionDropdown></ActionDropdown>
            </Group>
        </Flex>
        <Table>
        <Table.Thead>
        <Table.Tr>
            <Table.Th style={{width: 30}}></Table.Th>
            <Table.Th style={{width: 10}}></Table.Th>
            <Table.Th ></Table.Th>
            <Table.Th></Table.Th>
            <Table.Th style={{width: 30}}></Table.Th>
        </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {control?.sections.map(s => <ControlTableSection control={control} criteriaSection={s}></ControlTableSection>)}
        </Table.Tbody>
    </Table></div>
}