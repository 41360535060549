import { sortBy } from "@/libs/utils/sort"
import { Control, ControlCriteriaSection } from "./control"

const nistSectionLabels:  {[key: string]: string} = {
    "AC": "Access Control",
    "AT": "Awareness and Training",
    "AU": "Audit and Accountability",
    "CA": "Assessment, Authorization, and Monitoring",
    "CM": "Configuration Management",
    "CP": "Contingency Planning",
    "IA": "Identification and Authentication",
    "IR": "Incident Response",
    "MA": "Maintenance",
    "MP": "Media Protection",
    "PE": "Physical and Environmental Protection",
    "PL": "Planning",
    "PM": "Program Management",
    "PS": "Personnel Security",
    "RA": "Risk Assessment",
    "SA": "System and Services Acquisition",
    "SC": "System and Communications Protection",
    "SI": "System and Information Integrity"
}

const nistLabels:  {[key: string]: string} = {
    "AC-2":"Account Management",
    "AC-3":"Access Enforcement",
    "AC-4":"Information Flow Enforcement",
    "AC-5":"Separation of Duties",
    "AC-6":"Least Privilege",
    "AC-16":"Security and Privacy Attributes",
    "AC-17":"Remote Access",
    "AC-18":"Wireless Access",
    "AC-19":"Access Control for Mobile Devices",
    "AC-20":"Use of External Information Systems",
    "AC-21":"Information Sharing",
    "AT-2":"Security Awareness Training",
    "AT-3":"Role-Based Training",
    "AT-4":"Training Records",
    "AT-5":"Contact with Security Groups and Associations",
    "AU-1":"Audit and Accountability Policy and Procedures",
    "AU-2":"Audit Events",
    "AU-3":"Content of Audit Records",
    "AU-4":"Audit Storage Capacity",
    "AU-5":"Response to Audit Processing Failures",
    "AU-6":"Audit Review, Analysis, and Reporting",
    "AU-7":"Audit Reduction and Report Generation",
    "AU-8":"Time Stamps",
    "AU-9":"Protection of Audit Information",
    "AU-11":"Audit Record Retention",
    "AU-12":"Audit Generation",
    "AU-13":"Monitoring for Information Disclosure",
    "CA-2":"Control Assessments",
    "CA-3":"Information Exchange",
    "CA-6":"Authorization",
    "CA-7":"Continuous Monitoring",
    "CA-8":"Penetration Testing",
    "CA-9":"Internal System Connections",
    "CM-2":"Baseline Configuration",
    "CM-3":"Configuration Change Control",
    "CM-4":"Impact Analyses",
    "CM-5":"Access Restrictions for Change",
    "CM-6":"Configuration Settings",
    "CM-7":"Least Functionality",
    "CM-8":"System Component Inventory",
    "CM-9":"Configuration Management Plan",
    "CM-10":"Software Usage Restrictions",
    "CM-11":"User-Installed Software",
    "CP-2":"Contingency Plan",
    "CP-3":"Contingency Training",
    "CP-4":"Contingency Plan Testing",
    "CP-6":"Alternate Storage Site",
    "CP-8":"Telecommunications Services",
    "CP-9":"System Backup",
    "CP-10":"System Recovery and Reconstitution",
    "CP-11":"Alternate Communications Protocols",
    "IA-1":"Identification and Authentication Policy and Procedures",
    "IA-2":"Identification and Authentication (Organizational Users)",
    "IA-4":"Identifier Management",
    "IA-5":"Authenticator Management",
    "IA-6":"Authenticator Feedback",
    "IA-7":"Cryptographic Module Authentication",
    "IA-8":"Identification and Authentication (Non-Organizational Users)",
    "IR-3":"Incident Response Testing",
    "IR-4":"Incident Handling",
    "IR-5":"Incident Monitoring",
    "IR-6":"Incident Reporting",
    "IR-8":"Incident Response Plan",
    "MA-2":"Controlled Maintenance",
    "MA-3":"Maintenance Tools",
    "MA-4":"Nonlocal Maintenance",
    "MA-5":"Maintenance Personnel",
    "MP-2":"Media Access",
    "MP-4":"Media Storage",
    "MP-5":"Media Transport",
    "MP-6":"Media Sanitization",
    "MP-7":"Media Use",
    "PE-2":"Physical Access Authorizations",
    "PE-3":"Physical Access Control",
    "PE-4":"Access Control for Transmission Medium",
    "PE-5":"Access Control for Output Devices",
    "PE-6":"Monitoring Physical Access",
    "PE-9":"Power Equipment and Cabling",
    "PE-10":"Emergency Shutoff",
    "PE-11":"Emergency Power",
    "PE-12":"Emergency Lighting",
    "PE-13":"Fire Protection",
    "PE-14":"Temperature and Humidity Controls",
    "PE-15":"Water Damage Protection",
    "PE-16":"Delivery and Removal",
    "PE-18":"Location of Information System Components",
    "PE-19":"Information Leakage",
    "PE-20":"Asset Monitoring and Tracking",
    "PL-2":"System Security and Privacy Plans",
    "PL-8":"Information Security Architecture",
    "PM-1":"Program Management Policy and Procedures",
    "PM-4":"Plan of Action and Milestones Process",
    "PM-6":"Measures of Performance",
    "PM-8":"Critical Infrastructure Plan",
    "PM-9":"Risk Management Strategy",
    "PM-11":"Mission/Business Process Definition",
    "PM-12":"Insider Threat Program",
    "PM-13":"Security and Privacy Workforce",
    "PM-14":"Testing, Training, and Monitoring",
    "PM-15":"Contacts with Security Groups and Associations",
    "PM-16":"Threat Awareness Program",
    "PS-1":"Personnel Security Policy and Procedures",
    "PS-2":"Position Risk Designation",
    "PS-3":"Personnel Screening",
    "PS-4":"Personnel Termination",
    "PS-5":"Personnel Transfer",
    "PS-6":"Access Agreements",
    "PS-7":"Third-Party Personnel Security",
    "PS-8":"Personnel Sanctions",
    "RA-2":"Security Categorization",
    "RA-3":"Risk Assessment",
    "RA-4":"Risk Response",
    "RA-5":"Vulnerability Monitoring and Scanning",
    "SA-3":"System Development Life Cycle",
    "SA-4":"Acquisition Process",
    "SA-5":"System Documentation",
    "SA-8":"Security and Privacy Engineering Principles",
    "SA-9":"External Information System Services",
    "SA-10":"Developer Configuration Management",
    "SA-11":"Developer Security and Privacy Testing and Evaluation",
    "SA-15":"Development Process, Standards, and Tools",
    "SA-17":"Developer Security Architecture and Design",
    "SC-5":"Denial of Service Protection",
    "SC-7":"Boundary Protection",
    "SC-8":"Transmission Confidentiality and Integrity",
    "SC-13":"Cryptographic Protection",
    "SC-18":"Mobile Code",
    "SC-28":"Protection of Information at Rest",
    "SC-31":"Covert Channel Analysis",
    "SC-44":"Detonation Chambers",
    "SI-2":"Flaw Remediation",
    "SI-3":"Malicious Code Protection",
    "SI-4":"System Monitoring",
    "SI-5":"Security Alerts, Advisories, and Directives",
    "SI-7":"Software, Firmware, and Information Integrity"
 }
const hipaaLabels: {[key: string]: string} = {
    "164.308(a)(1)(ii)(D)":"Information System Activity Review",
    "164.308(a)(3)":"Workforce Security",
    "164.308(a)(3)(ii)(A)":"Authorization and/or Supervision",
    "164.308(a)(3)(ii)(B)":"Workforce Clearance Procedure",
    "164.308(a)(3)(ii)(C)":"Termination Procedures",
    "164.308(a)(4)":"Information Access Management",
    "164.308(a)(4)(i)":"Isolating Health Care Clearinghouse Functions",
    "164.308(a)(4)(ii)(B)":"Access Authorization",
    "164.308(a)(4)(ii)(C)":"Access Establishment and Modification",
    "164.308(a)(5)(ii)(B)":"Protection from Malicious Software",
    "164.308(a)(8)":"Evaluation",
    "164.310(a)(2)(iii)":"Access Control and Validation Procedures",
    "164.310(b)":"Workstation Use",
    "164.312(a)(1)":"Access Control",
    "164.312(a)(2)(i)":"Unique User Identification",
    "164.312(a)(2)(ii)":"Emergency Access Procedure",
    "164.312(a)(2)(iii)":"Automatic Logoff",
    "164.312(b)":"Audit Controls",
    "164.312(d)":"Person or Entity Authentication",
    "164.312(e)(2)(i)":"Integrity Controls",
    "164.310(c)":"Workstation Security",
    "164.312(a)(2)(iv)":"Encryption and Decryption",
    "164.308(a)(1)(ii)(A)":"Risk Analysis",
    "164.310(a)(1)":"Facility Access Controls",
    "164.310(d)":"Device and Media Controls",
    "164.312(a)":"Access Controls",
    "164.312(c)":"Integrity",
    "164.312(e)":"Transmission Security",
    "164.308(b)(1)":"Business Associate Contracts",
    "164.308(b)(3)":"Written Contract or Other Arrangement",
    "164.312(e)(1)":"Transmission Security Standard",
    "164.312(e)(2)(ii)":"Encryption",
    "164.308(a)(4)(ii)(A)":"Access Establishment and Modification",
    "164.308(b)":"Business Associate Contracts and Other Arrangements",
    "164.314(a)(1)":"Security Measures for Business Associates",
    "164.314(a)(2)(i)(B)":"Written Contract or Other Arrangement",
    "164.314(a)(2)(ii)":"Business Associate Agreements",
    "164.316(b)(2)":"Documentation Retention",
    "164.308(a)(6)(ii)":"Response and Reporting",
    "164.308(a)(5)":"Security Awareness and Training",
    "164.308(a)(2)":"Assigned Security Responsibility",
    "164.308(a)(3)(i)":"Workforce Security Policies",
    "164.308(a)(5)(i)":"Security Awareness Training",
    "164.308(a)(5)(ii)(A)":"Security Reminders",
    "164.308(a)(5)(ii)(C)":"Log-in Monitoring",
    "164.308(a)(5)(ii)(D)":"Password Management",
    "164.530(b)(1)":"Sanctions",
    "164.310(a)(2)(iv)":"Security Maintenance Records",
    "164.310(d)(2)(iii)":"Data Backup and Storage",
    "164.308(a)(1)(i)":"Security Management Process",
    "164.308(a)(6)(i)":"Security Incident Procedures",
    "164.314(a)(2)(i)(C)":"Risk Management",
    "164.314(a)(2)(iii)":"Documentation",
    "164.308(a)(6)":"Security Incident Procedures",
    "164.310(d)(1)":"Disposal",
    "164.314(b)(2)(i)":"Business Associate Agreement",
    "164.306(e)":"Security Standards Flexibility",
    "164.308(a)(7)(ii)(D)":"Testing and Revision Procedures",
    "164.308(a)(7)(ii)(E)":"Application and Data Criticality Analysis",
    "164.316(b)(2)(iii)":"Review Documentation",
    "164.308(a)(1)(ii)(B)":"Risk Management",
    "164.310(a)(2)(ii)":"Contingency Operations",
    "164.308(a)(7)(i)":"Contingency Plan",
    "164.308(a)(7)(ii)":"Implementation Specifications for Contingency Plans",
    "164.310(d)(2)":"Accountability",
    "164.308(a)(4)(ii)":"Information Access Management",
    "164.308(a)(7)":"Contingency Plan",
    "164.308(a)(7)(ii)(A)":"Data Backup Plan",
    "164.308(a)(7)(ii)(B)":"Disaster Recovery Plan",
    "164.308(a)(7)(ii)(C)":"Emergency Mode Operation Plan",
    "164.310(a)(2)(i)":"Access Control",
    "164.310(d)(2)(iv)":"Media Re-Use",
    "164.314":"Organizational Requirements",
    "164.316":"Policies and Procedures",
    "164.308.(a)(7)(ii)(E)":"Application and Data Criticality Analysis",
    "164.310(d)(2)(ii)":"Data Backup and Storage",
    "164.310(d)(2)(i)":"Disposal",
    "164.310":"Physical Safeguards",
    "164.314(b)(2)(iv)":"Amendments to Contracts",
    "164.308(a)(1)":"Security Management Process",
    "164.316(a)":"Policy Documentation",
    "164.308(a)(1)(ii)(C)":"Sanction Policy",
    "164.314(a)(2)(i)":"Business Associate Contract",
    "164.308(b)(2)":"Written Contracts",
    "164.312(c)(1)":"Integrity",
    "164.312(c)(2)":"Mechanisms to Authenticate ePHI"
 }

const hipaaToNist = {
    "164.308(a)(1)(ii)(D)": [
      "AC-2",  "AC-4",  "AC-5",  "AC-6",  "AC-17",
      "AC-18", "AU-1",  "AU-2",  "AU-3",  "AU-4",
      "AU-5",  "AU-6",  "AU-8",  "AU-9",  "AU-11",
      "AU-12", "AU-13", "CA-3",  "CA-7",  "CM-2",
      "CM-3",  "CM-8",  "CM-10", "CM-11", "CP-8",
      "IR-4",  "IR-5",  "MA-4",  "PE-3",  "PE-6",
      "PE-19", "PE-20", "PM-12", "PM-16", "PS-3",
      "PS-6",  "PS-7",  "RA-2",  "RA-3",  "SA-4",
      "SA-9",  "SC-5",  "SC-7",  "SC-8",  "SC-13",
      "SC-18", "SC-28", "SC-31", "SC-44", "SI-3",
      "SI-4",  "SI-5",  "SI-7"
    ],
    "164.308(a)(3)": [
      "AC-2",  "AC-3",  "AC-4",  "AC-5",
      "AC-6",  "AC-16", "CA-6", "CM-7",  "CP-2",
      "PE-19", "PM-1",  "PM-11", "PM-12",
      "PM-16", "PS-1",  "PS-2",  "PS-3",
      "PS-4",  "PS-5",  "PS-6",  "PS-7",
      "PS-8",  "RA-3",  "SC-7",  "SC-8",
      "SC-13", "SC-31", "SI-4",  "SI-5"
    ],
    "164.308(a)(3)(ii)(A)": [
      "AC-2",  "AC-4",  "AU-12",
      "AU-13", "CA-2",  "CA-3",
      "CA-7",  "CA-9",  "CM-8",
      "CM-10", "CM-11", "MA-2",
      "MA-3",  "MA-4",  "MA-5",
      "MP-2",  "MP-4",  "MP-5",
      "MP-7",  "PL-8",  "PM-14"
    ],
    "164.308(a)(3)(ii)(B)": [
      "AC-2",  "CA-2",
      "CA-7",  "IA-1",
      "IA-2",  "IA-4",
      "IA-5",  "IA-6",
      "IA-7",  "IA-8",
      "PM-14"
    ],
    "164.308(a)(3)(ii)(C)": [
      "AC-2", "IA-1",
      "IA-2", "IA-4",
      "IA-5", "IA-6",
      "IA-7", "IA-8"
    ],
    "164.308(a)(4)": [
      "AC-2",  "AC-3",  "AC-4",  "AC-5",
      "AC-6",  "AC-16", "CA-2",  "CA-6",
      "CA-7",  "CM-2",  "CM-4",  "CM-7",
      "CP-2",  "PE-19", "PM-1",  "PM-11",
      "PM-12", "PM-14", "PM-16", "PS-3",
      "PS-6",  "PS-7",  "RA-3",  "SC-7",
      "SC-8",  "SC-13", "SC-31", "SI-4",
      "SI-5"
    ],
    "164.308(a)(4)(i)": [
      "AC-2",  "AC-17",
      "AC-19", "AC-20",
      "IA-1",  "IA-2",
      "IA-4",  "IA-5",
      "IA-6",  "IA-7",
      "IA-8"
    ],
    "164.308(a)(4)(ii)(B)": [
      "AC-2", "AC-4",
      "IA-1", "IA-2",
      "IA-4", "IA-5",
      "IA-6", "IA-7",
      "IA-8", "SC-7"
    ],
    "164.308(a)(4)(ii)(C)": [
      "AC-2", "IA-1",
      "IA-2", "IA-4",
      "IA-5", "IA-6",
      "IA-7", "IA-8"
    ],
    "164.308(a)(5)(ii)(B)": [
      "AC-2",  "AT-3",  "AU-6",
      "AU-12", "CA-2",  "CA-7",
      "CM-3",  "CM-8",  "CP-2",
      "IR-4",  "IR-5",  "IR-6",
      "IR-8",  "PE-3",  "PE-6",
      "PE-20", "PM-13", "RA-5",
      "SC-5",  "SC-18", "SC-44",
      "SI-3",  "SI-4"
    ],
    "164.308(a)(8)": [
      "AC-2",  "AC-4",  "AU-6",  "AU-12",
      "CA-2",  "CA-3",  "CA-7",  "CA-8",
      "CA-9",  "CM-3",  "CM-4",  "CM-5",
      "CM-6",  "CM-7",  "CM-8",  "CM-9",
      "CP-2",  "CP-11", "IR-4",  "IR-8",
      "PL-2",  "PL-8",  "PM-6",  "PM-8",
      "PM-9",  "PM-11", "PM-14", "RA-2",
      "RA-3",  "RA-5",  "SA-5",  "SA-10",
      "SA-11", "SC-5",  "SI-2",  "SI-4",
      "SI-5"
    ],
    "164.310(a)(2)(iii)": [
      "AC-2",  "AC-3",  "AC-5",  "AC-6",
      "AC-16", "AU-12", "CA-2",  "CA-7",
      "CM-3",  "CM-7",  "CM-8",  "MP-6",
      "PE-2",  "PE-3",  "PE-4",  "PE-5",
      "PE-6",  "PE-9",  "PE-16", "PE-20",
      "PM-12", "PM-14", "PM-16", "RA-3",
      "SI-4",  "SI-5"
    ],
    "164.310(b)": [
      "AC-2",  "AC-3",  "AC-4",  "AC-5",
      "AC-6",  "AC-16", "AC-17", "AC-19",
      "AC-20", "AU-12", "CA-7",  "CM-3",
      "CM-7",  "CM-8",  "PE-2",  "PE-3",
      "PE-4",  "PE-5",  "PE-6",  "PE-9",
      "PE-19", "PE-20", "PS-3",  "PS-6",
      "SC-7",  "SC-8",  "SC-13", "SC-31",
      "SI-4"
    ],
    "164.312(a)(1)": [
      "AC-2",  "AC-3",  "AC-4",  "AC-5",
      "AC-6",  "AC-16", "AC-17", "AC-18",
      "CA-2",  "CA-6",  "CA-7",  "CA-8",
      "CM-7",  "CP-8",  "MP-2",  "MP-4",
      "MP-5",  "MP-7",  "PM-12", "PM-14",
      "PM-16", "RA-3",  "RA-5",  "SA-5",
      "SA-11", "SC-7",  "SC-28", "SI-2",
      "SI-5"
    ],
    "164.312(a)(2)(i)": [
      "AC-2",  "AC-3",  "AC-5",
      "AC-6",  "AC-16", "AU-12",
      "AU-13", "CA-7",  "CM-7",
      "CM-10", "CM-11", "IA-1",
      "IA-2",  "IA-4",  "IA-5",
      "IA-6",  "IA-7",  "IA-8"
    ],
    "164.312(a)(2)(ii)": [
      "AC-2",  "AC-3",  "AC-5",  "AC-6",
      "AC-16", "CA-2",  "CA-7",  "CM-7",
      "CP-2",  "CP-3",  "CP-8",  "CP-10",
      "CP-11", "IA-1",  "IA-2",  "IA-4",
      "IA-5",  "IA-6",  "IA-7",  "IA-8",
      "IR-3",  "IR-4",  "IR-8",  "MA-4",
      "PE-9",  "PE-11", "PM-14", "SC-5"
    ],
    "164.312(a)(2)(iii)": [
      "AC-2", "IA-1",
      "IA-2", "IA-4",
      "IA-5", "IA-6",
      "IA-7", "IA-8",
      "SC-28"
    ],
    "164.312(b)": [
      "AC-2",  "AC-4",  "AC-17", "AC-18",
      "AU-1",  "AU-2",  "AU-3",  "AU-4",
      "AU-5",  "AU-6",  "AU-8",  "AU-9",
      "AU-11", "AU-12", "AU-13", "CA-3",
      "CA-7",  "CM-2",  "CM-3",  "CM-8",
      "CM-10", "CM-11", "CP-8",  "IR-4",
      "IR-5",  "MA-4",  "MP-2",  "MP-4",
      "MP-5",  "MP-7",  "PE-3",  "PE-6",
      "PE-20", "SC-5",  "SC-7",  "SC-28",
      "SI-4",  "SI-7"
    ],
    "164.312(d)": [
      "AC-2", "AU-12", "AU-13",
      "CA-7", "CM-10", "CM-11",
      "IA-1", "IA-2",  "IA-4",
      "IA-5", "IA-6",  "IA-7",
      "IA-8", "MA-4",  "SC-28"
    ],
    "164.312(e)(2)(i)": [
      "AC-2", "AU-12",
      "CA-7", "CM-3",
      "SC-5", "SC-8",
      "SI-4", "SI-7",
      "CP-8"
    ],
    "164.310(c)": [
      "AC-3",  "AC-4",  "AC-5",
      "AC-6",  "AU-12", "CA-7",
      "CM-3",  "CM-7",  "CM-8",
      "PE-2",  "PE-3",  "PE-4",
      "PE-5",  "PE-6",  "PE-9",
      "PE-19", "PE-20", "PS-3",
      "PS-6",  "SC-7",  "SC-8",
      "SC-13", "SC-31", "SI-4"
    ],
    "164.312(a)(2)(iv)": [
      "AC-3", "CM-7",
      "MA-4", "MP-2",
      "MP-4", "MP-5",
      "MP-7", "SC-28"
    ],
    "164.308(a)(1)(ii)(A)": [
      "AC-4",  "CA-2",  "CA-3",
      "CA-7",  "CA-8",  "CA-9",
      "CM-8",  "CP-2",  "MP-6",
      "PE-16", "PL-8",  "PM-8",
      "PM-9",  "PM-11", "PM-12",
      "PM-16", "RA-2",  "RA-3",
      "RA-5",  "SA-5",  "SA-11",
      "SC-5",  "SI-2",  "SI-4",
      "SI-5"
    ],
    "164.310(a)(1)": [
      "AC-4",  "CA-2",  "CA-7",
      "CA-8",  "CM-3",  "CM-8",
      "PE-2",  "PE-3",  "PE-4",
      "PE-5",  "PE-6",  "PE-9",
      "PE-20", "PM-12", "PM-16",
      "RA-3",  "RA-5",  "SA-5",
      "SA-11", "SC-7",  "SI-2",
      "SI-4",  "SI-5"
    ],
    "164.310(d)": [ "AC-4", "CA-3", "CA-9", "CM-8", "PL-8", "SC-28" ],
    "164.312(a)": [
      "AC-4",  "AC-5",
      "AC-6",  "MA-4",
      "PE-19", "PS-3",
      "PS-6",  "SC-7",
      "SC-8",  "SC-13",
      "SC-31", "SI-4"
    ],
    "164.312(c)": [ "AC-4", "PM-12", "RA-3", "SC-7", "SC-28", "SI-5", "PM-16" ],
    "164.312(e)": [
      "AC-4",  "AC-17", "AC-18",
      "AU-12", "AU-13", "CA-7",
      "CM-10", "CM-11", "MA-4",
      "PE-19", "PM-12", "PM-16",
      "PS-3",  "PS-6",  "RA-3",
      "SC-7",  "SC-8",  "SC-13",
      "SC-31", "SI-5", "AC-5", "AC-6", "SI-4"
    ],
    "164.308(b)(1)": [
      "AC-17", "AC-19",
      "AC-20", "CP-2",
      "PM-11", "PS-7",
      "SC-8",  "SC-28"
    ],
    "164.308(b)(3)": [ "AC-17", "AC-19", "AC-20" ],
    "164.312(e)(1)": [ "AC-17", "AC-19", "AC-20", "SC-8" ],
    "164.312(e)(2)(ii)": [ "AC-17", "AC-19", "AC-20", "SC-8" ],
    "164.308(a)(4)(ii)(A)": [ "AC-20", "SA-9" ],
    "164.308(b)": [ "AC-20", "PM-9", "PM-11", "PS-7", "SA-9" ],
    "164.314(a)(1)": [
      "AC-20", "CA-6",
      "CP-8",  "PE-9",
      "PE-11", "PS-7",
      "SA-9"
    ],
    "164.314(a)(2)(i)(B)": [ "AC-20", "SA-9" ],
    "164.314(a)(2)(ii)": [ "AC-20", "PS-7", "SA-9" ],
    "164.316(b)(2)": [ "AC-20", "SA-9" ],
    "164.308(a)(6)(ii)": [
      "AC-21", "AU-6", "CA-2",
      "CA-7",  "CP-2", "CP-10",
      "CP-11", "IR-4", "IR-5",
      "IR-6",  "IR-8", "PE-6",
      "PM-8",  "RA-3", "RA-5",
      "SI-4"
    ],
    "164.308(a)(5)": [ "AT-2", "PM-13" ],
    "164.308(a)(2)": [
      "AT-3",  "CA-2",
      "CA-7",  "CP-2",
      "CP-3",  "IR-3",
      "IR-8",  "PM-1",
      "PM-11", "PM-13",
      "PM-14", "PS-7"
    ],
    "164.308(a)(3)(i)": [ "AT-3", "MP-2", "MP-4", "MP-5", "MP-7", "PM-13" ],
    "164.308(a)(5)(i)": [ "AT-3", "PM-13" ],
    "164.308(a)(5)(ii)(A)": [ "AT-3", "PM-12", "PM-13", "PM-16", "RA-3", "SI-5" ],
    "164.308(a)(5)(ii)(C)": [
      "AC-2", "AT-3",  "AU-1",  "AU-2",  "AU-3",
      "AU-4",  "AU-5",  "AU-6",  "AU-8",
      "AU-9",  "AU-11", "AU-12", "AU-13",
      "CA-2",  "CA-7",  "CM-3",  "CM-8",
      "CM-10", "CM-11", "CP-2",  "IR-4",
      "IR-5",  "IR-6",  "IR-8",  "PE-3",
      "PE-6",  "PE-20", "PM-13", "RA-5",
      "SC-5",  "SI-4"
    ],
    "164.308(a)(5)(ii)(D)": [ "AT-3", "PM-13" ],
    "164.530(b)(1)": [ "AT-3", "PM-13" ],
    "164.310(a)(2)(iv)": [
      "AU-1",  "AU-2", "AU-3",
      "AU-4",  "AU-5", "AU-6",
      "AU-8",  "AU-9", "AU-11",
      "AU-12", "CM-8", "MA-2",
      "MA-3",  "MA-5", "MP-6",
      "PE-16"
    ],
    "164.310(d)(2)(iii)": [
      "AU-1",  "AU-2", "AU-3",
      "AU-4",  "AU-5", "AU-6",
      "AU-8",  "AU-9", "AU-11",
      "AU-12", "CA-7", "CM-3",
      "CM-8",  "IR-4", "MA-4",
      "PE-2",  "PE-3", "PE-4",
      "PE-5",  "PE-6", "PE-9",
      "PE-20", "SI-4"
    ],
    "164.308(a)(1)(i)": [
      "AU-6",  "CA-2",  "CA-7",
      "IR-4",  "IR-5",  "PE-6",
      "PL-8",  "PM-1",  "PM-9",
      "PM-11", "PM-14", "PS-7",
      "RA-2",  "RA-3",  "RA-5",
      "SA-3",  "SA-4",  "SA-8",
      "SA-10", "SA-11", "SA-15",
      "SA-17", "SI-2",  "SI-4"
    ],
    "164.308(a)(6)(i)": [
      "AU-6", "CA-7",
      "CP-2", "CP-3",
      "IA-4", "IA-5",
      "IA-8", "IR-3",
      "IR-4", "IR-8",
      "SI-4"
    ],
    "164.314(a)(2)(i)(C)": [
      "AU-6", "CA-2",
      "CA-7", "CP-2",
      "IR-4", "IR-6",
      "IR-8", "PE-6",
      "PM-4", "PM-9",
      "RA-5", "SI-4"
    ],
    "164.314(a)(2)(iii)": [
      "AU-6", "CA-2",
      "CA-7", "IR-4",
      "IR-6", "IR-8",
      "RA-5", "SI-4"
    ],
    "164.308(a)(6)": [
      "AU-7",  "CP-2",
      "IR-4",  "IR-8",
      "PM-9",  "PM-11",
      "PM-15", "RA-2",
      "RA-3",  "SI-5"
    ],
    "164.310(d)(1)": [
      "AU-12", "CA-7",  "CM-3",
      "CM-8",  "MA-4",  "MP-2",
      "MP-4",  "MP-5",  "MP-6",
      "MP-7",  "PE-2",  "PE-3",
      "PE-4",  "PE-5",  "PE-6",
      "PE-9",  "PE-16", "PE-20",
      "SI-4"
    ],
    "164.314(b)(2)(i)": [
      "AU-12", "CA-7",  "CM-3",
      "CM-8",  "CP-2",  "CP-8",
      "CP-11", "PE-3",  "PE-6",
      "PE-9",  "PE-11", "PE-20",
      "SC-8",  "SC-28", "SI-4"
    ],
    "164.306(e)": [
      "CA-2", "CA-7",
      "CP-2", "IR-8",
      "PE-3", "PL-2",
      "PM-6", "PM-14",
      "RA-5", "SI-3",
      "SI-4"
    ],
    "164.308(a)(7)(ii)(D)": [
      "CA-2",  "CA-7",  "CP-2",
      "CP-4",  "CP-6",  "CP-9",
      "IR-3",  "IR-4",  "IR-8",
      "PL-2",  "PM-6",  "PM-11",
      "PM-14", "PM-16", "RA-2"
    ],
    "164.308(a)(7)(ii)(E)": [
      "CM-8", "CP-2", "CA-2",  "CA-7",  "CA-8",
      "IR-4",  "PM-8",  "PM-9",
      "PM-11", "PM-16", "RA-2",
      "RA-3",  "RA-5",  "SA-5",
      "SA-11", "SI-2",  "SI-4",
      "SI-5"
    ],
    "164.316(b)(2)(iii)": [
      "CA-2",  "CA-8",  "CP-2",
      "IR-4",  "IR-8",  "PE-10",
      "PE-12", "PE-13", "PE-14",
      "PE-15", "PE-18", "PL-2",
      "PM-6",  "PM-15", "RA-3",
      "RA-5",  "SA-5",  "SA-11",
      "SI-2",  "SI-4",  "SI-5"
    ],
    "164.308(a)(1)(ii)(B)": [
      "CA-7", "CP-2",  "CP-11",
      "IR-4", "PE-2",  "PE-3",
      "PE-4", "PE-5",  "PE-6",
      "PE-9", "PM-4",  "PM-8",
      "PM-9", "PM-11", "PM-16",
      "RA-2", "RA-3",  "RA-5",
      "SC-5", "SI-2"
    ],
    "164.310(a)(2)(ii)": [
      "CA-7",  "CM-3",
      "CM-8",  "MP-6",
      "PE-2",  "PE-3",
      "PE-4",  "PE-5",
      "PE-6",  "PE-16",
      "PE-20", "SI-4"
    ],
    "164.308(a)(7)(i)": [
      "CM-4",  "CM-5",  "CM-6",  "CM-7",
      "CM-9",  "CP-2",  "CP-8",  "CP-10",
      "IR-4",  "IR-8",  "PE-2",  "PE-3",
      "PE-4",  "PE-5",  "PE-6",  "PE-9",
      "PE-10", "PE-11", "PE-12", "PE-13",
      "PE-14", "PE-15", "PE-18", "PM-8",
      "PM-15", "SA-10"
    ],
    "164.308(a)(7)(ii)": [ "CM-4", "CM-5", "CM-6", "CM-7", "CM-9", "SA-10" ],
    "164.310(d)(2)": [
      "CM-8",  "MP-2",
      "MP-4",  "MP-5",
      "MP-6",  "MP-7",
      "PE-16"
    ],
    "164.308(a)(4)(ii)": [ "CP-2", "PM-8" ],
    "164.308(a)(7)": [ "CP-2", "CP-10", "CP-11", "IR-4", "IR-8", "SC-5" ],
    "164.308(a)(7)(ii)(A)": [
      "CP-2", "CP-3", "CP-4",
      "CP-6", "CP-9", "CP-10",
      "IR-3", "IR-4", "IR-8",
      "PE-2", "PE-3", "PE-4",
      "PE-5", "PE-6", "PE-9"
    ],
    "164.308(a)(7)(ii)(B)": [
      "CP-2", "CP-3",
      "CP-4", "CP-6",
      "CP-9", "CP-10",
      "IR-3", "IR-4",
      "IR-8", "PM-11"
    ],
    "164.308(a)(7)(ii)(C)": [
      "CP-2",  "CP-3",  "CP-10",
      "IR-3",  "IR-4",  "IR-8",
      "PE-10", "PE-12", "PE-13",
      "PE-14", "PE-15", "PE-18",
      "PM-8",  "PM-11", "PM-15"
    ],
    "164.310(a)(2)(i)": [
      "CP-2",  "CP-3",  "CP-4",
      "CP-6",  "CP-8",  "CP-9",
      "CP-10", "CP-11", "IR-3",
      "IR-4",  "IR-8",  "PE-2",
      "PE-3",  "PE-4",  "PE-5",
      "PE-6",  "PE-9",  "PE-11",
      "PM-8",  "PM-11", "SC-5"
    ],
    "164.310(d)(2)(iv)": [ "CP-2", "CP-4", "CP-6", "CP-9", "SC-5" ],
    "164.314": [
      "CP-2",  "PM-1",
      "PM-8",  "PM-11",
      "PM-12", "PM-16",
      "PS-7",  "RA-3",
      "SI-5"
    ],
    "164.316": [
      "CP-2",  "PM-1",
      "PM-8",  "PM-11",
      "PM-12", "PM-16",
      "PS-7",  "RA-3",
      "SI-5"
    ],
    "164.308.(a)(7)(ii)(E)": [ "CP-8", "PE-9", "PE-11" ],
    "164.310(d)(2)(ii)": [ "MA-4", "MP-6" ],
    "164.310(d)(2)(i)": [ "MP-6" ],
    "164.310": [
      "PE-10", "PE-12",
      "PE-13", "PE-14",
      "PE-15", "PE-18",
      "PM-15"
    ],
    "164.314(b)(2)(iv)": [ "PM-4", "PM-9" ],
    "164.308(a)(1)": [ "PM-9", "PM-11" ],
    "164.316(a)": [ "PM-9", "PM-11", "PM-16", "RA-2", "RA-3" ],
    "164.308(a)(1)(ii)(C)": [
      "PS-1", "PS-2",
      "PS-3", "PS-4",
      "PS-5", "PS-6",
      "PS-7", "PS-8"
    ],
    "164.314(a)(2)(i)": [ "PS-7", "SA-9" ],
    "164.308(b)(2)": [ "SC-8" ],
    "164.312(c)(1)": [ "SI-7" ],
    "164.312(c)(2)": [ "SI-7" ]
}

function buildNistSections(nist: string[]) {
    const map = nist.reduce((acc, cur) => {
        const section = cur.split("-")[0]
        acc[section] = acc[section] || []
        acc[section].push(cur)
        acc[section].sort()
        return acc
    }, {} as {[key: string]: string[]}) 
    return Object.entries(map).sort(sortBy(e => e[0])).reduce((acc, cur) => {
        acc.push({
            id: cur[0],
            label: nistSectionLabels[cur[0]],
            criteria: cur[1].map(c => ({
                label: c + ": " + nistLabels[c],
                id: c,
                mode: 'AUTOMATIC',
                enabled: true,
                data: {},
                progress: 0,
            })),
        })
        return acc
    }, [] as ControlCriteriaSection[])
}

const HIPAA_CONTROLS: Control[] = Object.entries(hipaaToNist)
    .sort(sortBy(e => e[0]))
    .reduce((acc, cur) => {
        acc.push({
            id: cur[0].toLowerCase(),
            label: cur[0] + ": " + hipaaLabels[cur[0]],
            sections: buildNistSections(cur[1]),
            type: 'hipaa',
            enabled: true,
            parameters: {},
        })
        return acc
    }, [] as Control[])
export default HIPAA_CONTROLS